<template>
  <div v-if="rootStore.fetched">
    <Navigation />
    <router-view />
  </div>
  <div v-else class="flex justify-center items-center bg-black w-screen h-screen overflow-hidden">
    <img src="./assets/ul-loader.svg" alt="Loader" class="h-10 sm:h-16 md:h-24 text-white animate-rotate" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import Navigation from './components/Navigation.vue'
import { useRootStore } from './stores'

const rootStore = useRootStore()
onMounted(async () => {
  await rootStore.fetch()
})
</script>

<style lang="scss">
$fontWeightsWorkSans: 100, 200, 300, 400, 500, 600, 700, 800, 900;

$fontWeightsRebond: 300, 400, 500, 600, 700;

@each $fontWeight in $fontWeightsWorkSans {
  @font-face {
    font-family: 'Work Sans';
    src: url('assets/fonts/work-sans/WorkSans-#{$fontWeight}.ttf') format('truetype');
    font-weight: $fontWeight;
    font-style: normal;
  }
  @font-face {
    font-family: 'Work Sans';
    src: url('assets/fonts/work-sans/WorkSans-#{$fontWeight}i.ttf') format('truetype');
    font-weight: $fontWeight;
    font-style: italic;
  }
}
@each $fontWeight in $fontWeightsRebond {
  @font-face {
    font-family: 'Rebond Grotesque';
    src: url('assets/fonts/rebond-grotesque/ESRebondGrotesque-#{$fontWeight}.ttf') format('truetype');
    font-weight: $fontWeight;
    font-style: normal;
  }
  @font-face {
    font-family: 'Rebond Grotesque';
    src: url('assets/fonts/rebond-grotesque/ESRebondGrotesque-#{$fontWeight}i.ttf') format('truetype');
    font-weight: $fontWeight;
    font-style: italic;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Work Sans', 'Open Sans', sans-serif;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rebond Grotesque', 'Work Sans', 'Open Sans', sans-serif;
  font-weight: 600;
}

.v-html {
  & p {
    margin-block-end: 0.5em;
    color: inherit;
  }
  & a {
    @apply text-ul-blue-300;
    @apply underline;

    &:visited {
      @apply text-purple-700;
    }
  }
  & ul {
    @apply list-disc;
    @apply list-outside;
    @apply ml-4;
  }
  & ol {
    @apply list-decimal;
    @apply list-outside;
    @apply ml-4;
  }
}

.ul-scroll {
  &::-webkit-scrollbar {
    @apply w-2;
    @apply bg-ul-gray-200;
  }

  // &::-webkit-scrollbar-track {
  // }

  &::-webkit-scrollbar-thumb {
    @apply bg-ul-gray-300;
  }

  &::-webkit-scrollbar-thumb:hover {
    @apply bg-ul-gray-300;
  }

  &::-webkit-scrollbar-thumb:active {
    @apply bg-ul-gray-400;
  }
}
</style>
