<template>
  <div class="w-full bg-white min-h-view h-view overflow-auto">
    <!-- DESKTOP VERSION -->
    <div class="max-w-screen-lg hidden lg:flex flex-row mx-auto h-view px-4">
      <div class="w-1/5 py-16 h-view">
        <div class="flex flex-col h-full w-full overflow-auto ul-scroll">
          <button
            class="text-md p-1 text-left focus:outline-none"
            :class="[activeIndex === null ? 'font-semibold' : 'font-medium']"
            @click="activeIndex = null"
          >
            Vilkår for bruk
          </button>
          <hr class="mr-5 my-3 border border-ul-orange-150" />
          <div v-for="resourceGroup in resourceGroups" :key="resourceGroup.title" class="pb-4 flex flex-col">
            <p class="tracking-wide font-bold text-sm opacity-85">
              {{ resourceGroup.title }}
            </p>
            <button
              v-for="resource in resourceGroup.resources"
              :key="resource.id"
              @click="activeIndex = getIndex(resource)"
              class="text-md p-1 text-left focus:outline-none"
              :class="[getIndex(resource) === activeIndex ? 'font-semibold text-ul-orange-200' : 'font-medium']"
            >
              {{ resource.subtitle }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="activeResource === null" class="w-5/6 pl-16 pt-16 h-view overflow-auto">
        <p class="py-2">
          Vi vil gjerne at sangene deles og brukes, og gjør derfor noteressurser tilgjengelig helt gratis, under
          betingelsen at du og din forsamling innrapporterer musikkbruken til TONO.
        </p>
        <p class="py-2">
          Slik rapportering er faktisk helt gratis for alle menigheter og forsamlinger, men den positive effekten for
          låtskrivere og tekstforfattere er betydelig likevel!
        </p>
        <p class="py-2">
          Les mer på
          <a
            href="https://www.tono.no/kunder/ovrig-musikkbruk/#anchor_collapsekirke"
            class="underline text-blue-500 visited:text-purple-500"
            >TONOs nettsider</a
          >
          under "Kirker og øvrige trossamfunn" for konkret info.
        </p>
      </div>
      <div v-else class="w-4/5 pl-16 pt-16 h-view overflow-auto">
        <h1 class="text-xl font-bold">
          {{ activeResource.title }} <span v-if="activeResource.subtitle">- {{ activeResource.subtitle }}</span>
        </h1>
        <iframe
          v-if="isPdf(activeResource.filename)"
          :src="getFileURL(activeResource.filename)"
          frameborder="0"
          class="w-full mt-4"
          style="height: calc(100vh - 236px)"
        />
        <img
          v-else
          :src="getFileURL(activeResource.filename)"
          :alt="activeResource.title"
          class="w-full py-4 object-contain"
          :key="activeResource.id"
        />
      </div>
    </div>
    <!-- MOBILE VERSION -->
    <div class="lg:hidden sm:my-4 sm:mx-8">
      <div class="p-4">
        <p class="py-2">
          Vi vil gjerne at sangene deles og brukes, og gjør derfor noteressurser tilgjengelig helt gratis, under
          betingelsen at du og din forsamling innrapporterer musikkbruken til TONO.
        </p>
        <p class="py-2">
          Slik rapportering er faktisk helt gratis for alle menigheter og forsamlinger, men den positive effekten for
          låtskrivere og tekstforfattere er betydelig likevel!
        </p>
        <p class="py-2">
          Les mer på
          <a
            href="https://www.tono.no/kunder/ovrig-musikkbruk/#anchor_collapsekirke"
            class="underline text-blue-500 visited:text-purple-500"
          >
            TONOs nettsider
          </a>
          under "Kirker og øvrige trossamfunn" for konkret info.
        </p>
      </div>
      <hr class="my-4" />
      <div class="flex flex-row flex-wrap justify-center">
        <a
          v-for="resource in resources"
          :key="resource.id"
          class="min-w-64 w-full sm:w-64 p-4 pr-16 min-h-20 m-2 shadow bg-white relative cursor-pointer outline-none text-left"
          :href="getFileURL(resource.filename)"
          rel="noopener noreferrer"
          target="_blank"
        >
          <h3 class="font-semibold">{{ resource.title }}</h3>
          <h4 class="italic">{{ resource.subtitle }}</h4>
          <!-- Open in new tab-icon -->
          <svg viewBox="0 0 24 24" class="absolute top-0 right-0 h-20 w-20 p-6 text-ul-orange-400">
            <path
              fill="currentColor"
              d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"
            />
          </svg>
        </a>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { computed } from 'vue'

import PageFooter from '../components/PageFooter.vue'
import { useResourceStore } from '../stores/resources'
import { Resource } from '../types'

const activeIndex = ref<number | null>(null)

const resourceStore = useResourceStore()
const resources = computed(() =>
  resourceStore.resources
    .toSorted((a, b) => (a.subtitle > b.subtitle ? 1 : -1))
    .toSorted((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0))
    .toSorted((a, b) => (b.title === 'Plakater' ? 1 : a.title == 'Plakater' ? -1 : 0))
)

function getIndex(resource: Resource): number {
  return resources.value.findIndex((r) => r.id === resource.id)
}

type ResourceGroup = { title: string; resources: Resource[] }
const resourceGroups = computed(() => {
  const groups: ResourceGroup[] = []
  for (const resource of resources.value) {
    const groupTitle = resource.title.toUpperCase()
    if (!groups.find((group) => group.title === groupTitle)) {
      groups.push({ title: groupTitle, resources: [] })
    }
    groups.find((group) => group.title === groupTitle)!.resources.push(resource)
  }
  return groups
})

const activeResource = computed(() => (activeIndex.value !== null ? resources.value[activeIndex.value] : null))

function getFileURL(filename: string) {
  return `https://firebasestorage.googleapis.com/v0/b/ulno-1557913587891.appspot.com/o/resources%2F${encodeURI(
    filename
  )}?alt=media`
}

function isPdf(filename: string) {
  return filename.endsWith('.pdf')
}
</script>

<style lang="scss"></style>
