import { defineStore } from 'pinia'

import { Sponsor } from '../types'
import { useReactiveList } from './utils'

export const useSponsorStore = defineStore('sponsor', () => {
  const { list: sponsors, populate } = useReactiveList<Sponsor>()

  return { sponsors, populate }
})
