export const icons = {
  youtube:
    'M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z',
  spotify:
    'M17.9,10.9C14.7,9 9.35,8.8 6.3,9.75C5.8,9.9 5.3,9.6 5.15,9.15C5,8.65 5.3,8.15 5.75,8C9.3,6.95 15.15,7.15 18.85,9.35C19.3,9.6 19.45,10.2 19.2,10.65C18.95,11 18.35,11.15 17.9,10.9M17.8,13.7C17.55,14.05 17.1,14.2 16.75,13.95C14.05,12.3 9.95,11.8 6.8,12.8C6.4,12.9 5.95,12.7 5.85,12.3C5.75,11.9 5.95,11.45 6.35,11.35C10,10.25 14.5,10.8 17.6,12.7C17.9,12.85 18.05,13.35 17.8,13.7M16.6,16.45C16.4,16.75 16.05,16.85 15.75,16.65C13.4,15.2 10.45,14.9 6.95,15.7C6.6,15.8 6.3,15.55 6.2,15.25C6.1,14.9 6.35,14.6 6.65,14.5C10.45,13.65 13.75,14 16.35,15.6C16.7,15.75 16.75,16.15 16.6,16.45M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z',
  podcast:
    'M17,18.25V21.5H7V18.25C7,16.87 9.24,15.75 12,15.75C14.76,15.75 17,16.87 17,18.25M12,5.5A6.5,6.5 0 0,1 18.5,12C18.5,13.25 18.15,14.42 17.54,15.41L16,14.04C16.32,13.43 16.5,12.73 16.5,12C16.5,9.5 14.5,7.5 12,7.5C9.5,7.5 7.5,9.5 7.5,12C7.5,12.73 7.68,13.43 8,14.04L6.46,15.41C5.85,14.42 5.5,13.25 5.5,12A6.5,6.5 0 0,1 12,5.5M12,1.5A10.5,10.5 0 0,1 22.5,12C22.5,14.28 21.77,16.39 20.54,18.11L19.04,16.76C19.96,15.4 20.5,13.76 20.5,12A8.5,8.5 0 0,0 12,3.5A8.5,8.5 0 0,0 3.5,12C3.5,13.76 4.04,15.4 4.96,16.76L3.46,18.11C2.23,16.39 1.5,14.28 1.5,12A10.5,10.5 0 0,1 12,1.5M12,9.5A2.5,2.5 0 0,1 14.5,12A2.5,2.5 0 0,1 12,14.5A2.5,2.5 0 0,1 9.5,12A2.5,2.5 0 0,1 12,9.5Z',
  tiktok:
    'M 19.679688 1.921875 L 4.320312 1.921875 C 2.996094 1.921875 1.921875 2.996094 1.921875 4.320312 L 1.921875 19.679688 C 1.921875 21.003906 2.996094 22.078125 4.320312 22.078125 L 19.679688 22.078125 C 21.003906 22.078125 22.078125 21.003906 22.078125 19.679688 L 22.078125 4.320312 C 22.078125 2.996094 21.003906 1.921875 19.679688 1.921875 Z M 17.761719 10.714844 C 17.652344 10.726562 17.542969 10.730469 17.433594 10.730469 C 16.171875 10.730469 15.066406 10.085938 14.421875 9.105469 C 14.421875 11.671875 14.421875 14.59375 14.421875 14.644531 C 14.421875 16.902344 12.589844 18.734375 10.328125 18.734375 C 8.070312 18.734375 6.238281 16.902344 6.238281 14.644531 C 6.238281 12.382812 8.070312 10.550781 10.328125 10.550781 C 10.414062 10.550781 10.5 10.558594 10.582031 10.5625 L 10.582031 12.582031 C 10.5 12.570312 10.414062 12.554688 10.328125 12.554688 C 9.175781 12.554688 8.242188 13.488281 8.242188 14.644531 C 8.242188 15.796875 9.175781 16.734375 10.328125 16.734375 C 11.484375 16.734375 12.503906 15.824219 12.503906 14.667969 C 12.503906 14.625 12.523438 5.265625 12.523438 5.265625 L 14.449219 5.265625 C 14.632812 6.988281 16.023438 8.347656 17.761719 8.472656 Z M 17.761719 10.714844 ',
  instagram:
    'M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z',
  facebook:
    'M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z',
  menu: 'M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z',
  chevronDown:
    'M0.566201 8.32476C-0.191771 7.56191 -0.188021 6.32649 0.573232 5.56739C1.33495 4.80783 2.5673 4.81158 3.32528 5.57444L12 14.2895L20.6747 5.57444C21.4327 4.81158 22.665 4.80783 23.4268 5.56739C24.188 6.32649 24.1918 7.56191 23.4338 8.32476L13.372 18.4326C12.6108 19.1922 11.378 19.1884 10.6205 18.4256L0.566201 8.32476Z',
}
export type IconKey = keyof typeof icons
