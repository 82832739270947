<template>
  <label for="ul-toggle" class="flex w-full gap-4 cursor-pointer select-none items-center" @click="click">
    <div class="relative">
      <input type="checkbox" id="ul-pluss-toggle" class="sr-only" :value="modelValue" />
      <div
        class="box block h-8 w-14 rounded-full transition-all duration-200"
        :style="`background: ${modelValue ? '#F34E35' : '#cccccc'}`"
      />
      <div
        class="dot absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition-all duration-200 transform"
        :class="[modelValue ? 'translate-x-full' : '']"
      />
    </div>
    <span>
      {{ label }}
    </span>
  </label>
</template>

<script setup lang="ts">
type Props = {
  modelValue?: boolean
  activeColor?: string
  label?: string
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  label: '',
})
const emit = defineEmits<{ 'update:modelValue': [value: boolean] }>()

function click() {
  emit('update:modelValue', !props.modelValue)
}
</script>

<style></style>
