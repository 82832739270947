<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
    <path :d="path" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { IconKey, icons } from './icons'

const props = defineProps<{ icon: IconKey }>()
const path = computed(() => icons[props.icon])
</script>

<style lang="scss"></style>
