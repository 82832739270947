<template>
  <div class="about bg-white h-view overflow-auto">
    <div>
      <AboutArticle v-for="(article, idx) in articles" :key="article.id" :article="article" :index="idx" />
    </div>
    <!-- <ImageGallery :images="images" /> -->
    <PageFooter />
  </div>
</template>

<script setup lang="ts">
// import ImageGallery from '../components/ImageGallery.vue'
import { computed } from 'vue'

import AboutArticle from '../components/AboutArticle.vue'
import PageFooter from '../components/PageFooter.vue'
import { useAboutStore } from '../stores/about'

const aboutStore = useAboutStore()
const articles = computed(() => aboutStore.articles.toSorted((a, b) => b.priority - a.priority))
</script>

<style lang="scss"></style>
