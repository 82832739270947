import { defineStore } from 'pinia'
import { ref } from 'vue'

import { Item } from '../types'
import { useAboutStore } from './about'
import { useEventStore } from './events'
import { useInformationStore } from './information'
import { useResourceStore } from './resources'
import { useSeminarStore } from './seminars'
import { useSponsorStore } from './sponsors'
import { useStreamStore } from './stream'
import { useTicketStore } from './ticket'

function extractIds<T extends Item>(record: Record<string, Omit<T, 'id'>> = {}): T[] {
  return Object.entries(record).map(([id, item]) => ({ id, ...item })) as T[]
}
export const DATABASE_URL = import.meta.env.PROD
  ? 'https://ul-no-prod.europe-west1.firebasedatabase.app/.json'
  : 'https://ul-no-dev.europe-west1.firebasedatabase.app/.json'

export const useRootStore = defineStore('root', () => {
  const fetched = ref(false)

  async function fetch() {
    const response = await window.fetch(DATABASE_URL)
    const data = await response.json()

    useAboutStore().populate(extractIds(data.about))
    useInformationStore().populate(extractIds(data.information))
    useSeminarStore().populate(extractIds(data.seminars))
    useEventStore().populate(extractIds(data.events))
    useResourceStore().populate(extractIds(data.resources))
    useSponsorStore().populate(extractIds(data.sponsors))
    useTicketStore().populate(extractIds(data.tickets))
    useStreamStore().populate(data.stream)

    fetched.value = true
  }

  return { fetch, fetched }
})
