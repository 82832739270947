<template>
  <div
    class="bg-ul-orange-300 flex items-center justify-center rounded-full"
    :style="`width: ${outerSize}px; height: ${outerSize}px;`"
  >
    <div
      class="bg-ul-orange-200 flex items-center justify-center rounded-full"
      :style="`width: ${middleSize}px; height: ${middleSize}px;`"
    >
      <div
        class="bg-ul-orange-100 flex items-center justify-center rounded-full"
        :style="`width: ${innerSize}px; height: ${innerSize}px;`"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = { radius?: number }
const props = withDefaults(defineProps<Props>(), { radius: 16 })
const innerSize = computed(() => props.radius * 2)
const middleSize = computed(() => props.radius * 4)
const outerSize = computed(() => props.radius * 6)
</script>

<style></style>
