<template>
  <i class="ph-bold" :class="`ph-${iconName}`" :style="`font-size: ${fontSize}`" />
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  iconName: string
  size?: number
}
const props = withDefaults(defineProps<Props>(), {
  size: undefined,
})
const fontSize = computed(() => {
  if (props.size === undefined) return 'inherit'
  return `${props.size}px`
})
</script>

<style lang="scss"></style>
