<template>
  <div class="flex justify-center items-center text-white font-display font-medium relative overflow-hidden">
    <img class="w-full h-full object-cover" :src="props.image" role="presentation" />
    <router-link :to="props.link" class="absolute inset-0 flex justify-center items-center hover:scale-105">
      <div class="bg-opacity-50 p-4" :class="[props.color === 'orange' ? 'bg-ul-orange-300' : 'bg-ul-gray-400']">
        <div class="text-center">{{ props.text }}</div>
      </div>
    </router-link>
  </div>
</template>

<script setup lang="ts">
type Props = {
  image: string
  text: string
  link: string
  color?: 'orange' | 'gray'
}

const props = withDefaults(defineProps<Props>(), {
  color: 'orange',
})
</script>

<style lang="scss"></style>
