import { defineStore } from 'pinia'

import { Ticket } from '../types'
import { useReactiveList } from './utils'

export const useTicketStore = defineStore('ticket', () => {
  const { list: tickets, populate } = useReactiveList<Ticket>()

  return { tickets, populate }
})
