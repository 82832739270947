import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useStreamStore = defineStore('stream', () => {
  const streamId = ref('')
  const nextStreamDate = ref<Date | null>(null)

  async function populate(data?: { id: string; nextStream: string }) {
    if (!data) return

    streamId.value = data.id
    const nextStreamDateValue = new Date(data.nextStream)
    if (!Number.isNaN(nextStreamDateValue.getTime())) {
      nextStreamDate.value = nextStreamDateValue
    }
  }

  return { streamId, nextStreamDate, populate }
})
