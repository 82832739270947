<template>
  <div class="bg-white h-view overflow-auto">
    <div class="mx-4 lg:max-w-screen-lg lg:mx-auto text-center">
      <h3 class="text-xl font-semibold py-16 p-4">
        Vi vil rekke en stor takk til våre samarbeidspartnere som gjør det mulig å arrangere UL!
      </h3>
      <div class="grid gap-4 sm:gap-12 lg:gap-24 grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 pb-6 mb-12">
        <a
          v-for="sponsor in sponsors"
          :key="sponsor.id"
          :style="{ backgroundImage: `url(${sponsor.image})` }"
          :href="sponsor.link"
          target="_blank"
          rel="noopener noreferrer"
          class="w-full bg-center bg-cover shadow-xl transition-all duration-100 hover:shadow-2xl hover:scale-105 aspect-video"
          :class="[sponsor.link ? '' : '']"
        >
        </a>
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import PageFooter from '../components/PageFooter.vue'
import { useSponsorStore } from '../stores/sponsors'

const sponsorStore = useSponsorStore()
const sponsors = sponsorStore.sponsors.toSorted(() => 0.5 - Math.random())
</script>

<style lang="scss"></style>
