import { defineStore } from 'pinia'

import { AboutArticle } from '../types'
import { useReactiveList } from './utils'

export const useAboutStore = defineStore('about', () => {
  const { list: articles, populate } = useReactiveList<AboutArticle>()

  return { articles, populate }
})
