<template>
  <div
    class="overflow-hidden flex"
    :style="`width: ${totalWidth}px; height: ${totalHeight}px;`"
    :class="[direction == 'north' || direction == 'west' ? 'justify-start' : 'justify-end']"
  >
    <div>
      <FullCircle :radius="radius" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import FullCircle from './FullCircle.vue'

type CardinalDirection = 'north' | 'east' | 'south' | 'west'

type Props = {
  radius?: number
  direction?: CardinalDirection
}
const props = withDefaults(defineProps<Props>(), {
  radius: 64,
  direction: 'north',
})

const totalHeight = computed(() => {
  switch (props.direction) {
    case 'north':
    case 'south':
    default:
      return props.radius * 3
    case 'east':
    case 'west':
      return props.radius * 6
  }
})
const totalWidth = computed(() => {
  switch (props.direction) {
    case 'north':
    case 'south':
    default:
      return props.radius * 6
    case 'east':
    case 'west':
      return props.radius * 3
  }
})
</script>

<style></style>
