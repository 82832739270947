import { defineStore } from 'pinia'

import { Event } from '../types'
import { useReactiveList } from './utils'

export const useEventStore = defineStore('event', () => {
  const { list: events, populate } = useReactiveList<Event>()

  return { events, populate }
})
