import { reactive } from 'vue'

export function useReactiveList<T>() {
  const list: T[] = reactive([])

  function populate(newList: T[]) {
    list.length = 0
    list.push(...newList)
  }

  return { list, populate }
}
