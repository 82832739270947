import { defineStore } from 'pinia'

import { Seminar } from '../types'
import { useReactiveList } from './utils'

export const useSeminarStore = defineStore('seminar', () => {
  const { list: seminars, populate } = useReactiveList<Seminar>()

  return { seminars, populate }
})
