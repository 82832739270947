import { defineStore } from 'pinia'

import { Resource } from '../types'
import { useReactiveList } from './utils'

export const useResourceStore = defineStore('resource', () => {
  const { list: resources, populate } = useReactiveList<Resource>()

  return { resources, populate }
})
