import { createRouter, createWebHistory } from 'vue-router'

import About from '../views/About.vue'
import Home from '../views/Home.vue'
import Info from '../views/Info.vue'
import Pluss from '../views/Pluss.vue'
import Program from '../views/Program.vue'
import Resources from '../views/Resources.vue'
import Seminars from '../views/Seminars.vue'
import Sponsors from '../views/Sponsors.vue'
import Tickets from '../views/Tickets.vue'

export const routes = [
  {
    path: '/',
    name: 'Forside',
    component: Home,
  },
  {
    path: '/om',
    name: 'Om',
    component: About,
  },
  {
    path: '/info',
    name: 'Info',
    component: Info,
  },
  {
    path: '/program',
    name: 'Program',
    component: Program,
  },
  {
    path: '/seminarer',
    name: 'Seminarer',
    component: Seminars,
  },
  {
    path: '/billetter',
    name: 'Billetter',
    component: Tickets,
  },
  {
    path: '/ul-pluss',
    name: 'UL+',
    component: Pluss,
  },
  {
    path: '/ressurser',
    name: 'Ressurser',
    component: Resources,
  },
  {
    path: '/partnere',
    name: 'Partnere',
    component: Sponsors,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach((to, _from, next) => {
  if (to.matched.length > 0) {
    const matched = to.matched[0]
    document.title = `UL 2025 - ${String(matched.name)}`
  } else {
    document.title = 'UL 2025'
    next('/')
  }
  next()
})

export default router
